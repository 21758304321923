import { all } from 'redux-saga/effects'
import user from './user/sagas'
import menu from './menu/sagas'
import questions from './questions/sagas'
import groups from './groups/sagas'
import schools from './schools/sagas'
import bookings from './bookings/sagas'
import configs from './configs/sagas'
import settings from './settings/sagas'

export default function* rootSaga() {
  yield all([user(), menu(), settings(), questions(), groups(), schools(), bookings(), configs()])
}
