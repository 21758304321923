import _ from 'lodash'
import { all, put, call, takeEvery } from 'redux-saga/effects'
import getGroupsData from 'services/backend/groups'
import actions from './actions'

export function* GET_DATA() {
  yield put({
    type: 'groups/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const groups = yield call(getGroupsData)
  const schools = _.chain(groups)
    .map(g => g.school)
    .uniqBy('id')
    .map(s => ({ ...s, groups: groups.filter(g => g.school.id === s.id) }))
    .value()

  yield put({
    type: 'groups/SET_STATE',
    payload: {
      groups,
      schools,
      loading: false,
      ready: true,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_DATA, GET_DATA)])
}
