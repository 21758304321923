import React from 'react'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'
import { connect } from 'react-redux'

import english from './locales/en-US'
import portuguese from './locales/pt-BR'

const locales = {
  'en-US': english,
  'pt-BR': portuguese,
}

const mapStateToProps = ({ settings }) => ({ settings })

function Localization({ children, settings: { locale } }) {
  const currentLocale = locales[locale]
  return (
    <ConfigProvider locale={currentLocale.localeAntd}>
      <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
        {children}
      </IntlProvider>
    </ConfigProvider>
  )
}

export default connect(mapStateToProps)(Localization)
