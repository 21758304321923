import { all, call, put, takeEvery } from 'redux-saga/effects'
import getBookingRooms from 'services/backend/bookings'
import actions from './actions'

export function* GET_DATA() {
  yield put({
    type: 'bookings/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const bookingRooms = yield call(getBookingRooms)

  yield put({
    type: 'bookings/SET_STATE',
    payload: {
      rooms: bookingRooms,
      loading: false,
      ready: true,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_DATA, GET_DATA)])
}
