import React from 'react'
import style from './style.module.scss'

function Footer() {
  return (
    <div className={style.footer}>
      <div className={style.footerInner}>
        <a
          href="https://portal.stift.com.br"
          target="_blank"
          rel="noopener noreferrer"
          className={style.logo}
        >
          PORTAL STIFT
        </a>
        <br />
        <p className="mb-0">
          Copyright © {new Date(Date.now()).getFullYear()} Stift LTDA |{' '}
          <a href="https://stift.com.br/legal" target="_blank" rel="noopener noreferrer">
            Legal
          </a>
        </p>
      </div>
    </div>
  )
}

export default Footer
