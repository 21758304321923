import localeAntd from 'antd/es/locale/pt_BR'

const messages = {
  'topBar.issuesHistory': 'Issues History',
  'topBar.projectManagement': 'Project Management',
  'topBar.typeToSearch': 'Pesquise...',
  'topBar.findPages': 'Find pages...',
  'topBar.actions': 'Actions',
  'topBar.status': 'Status',
  'topBar.profileMenu.hello': 'Olá',
  'topBar.profileMenu.billingPlan': 'Billing Plan',
  'topBar.profileMenu.role': 'Função',
  'topBar.profileMenu.email': 'E-mail',
  'topBar.profileMenu.phone': 'Telefone',
  'topBar.profileMenu.editProfile': 'Editar perfil',
  'topBar.profileMenu.logout': 'Sair',
}

export default {
  locale: 'pt-BR',
  localeAntd,
  messages,
}
