import { all, put, call, takeEvery } from 'redux-saga/effects'
import getSchoolsData from 'services/backend/schools'
import actions from './actions'

export function* GET_DATA() {
  yield put({
    type: 'schools/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const schools = yield call(getSchoolsData)

  yield put({
    type: 'schools/SET_STATE',
    payload: {
      schools,
      loading: false,
      ready: true,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_DATA, GET_DATA)])
}
