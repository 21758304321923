import { all, put, call, takeEvery } from 'redux-saga/effects'
import getConfigsData from 'services/backend/configs'
import actions from './actions'

export function* GET_DATA() {
  yield put({
    type: 'configs/SET_STATE',
    payload: {
      loading: true,
    },
  })

  const configs = yield call(getConfigsData)

  const { disciplines, subjects, statuses } = configs

  yield put({
    type: 'configs/SET_STATE',
    payload: {
      disciplines,
      subjects,
      statuses,
      loading: false,
      ready: true,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_DATA, GET_DATA)])
}
