import actions from './actions'

const initialState = {
  disciplines: [],
  subjects: [],
  statuses: [],
  loading: false,
  ready: false,
}

export default function configsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
