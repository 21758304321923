import actions from './actions'

const initialState = {
  data: [],
  count: null,
  limit: 100,
  offset: 0,
  filters: {
    query: null,
    school_ids: null,
    group_ids: null,
    discipline_ids: null,
    start_date: null,
    end_date: null,
    rating_range: null,
    status_id: null,
    has_reply: null,
    user_ids: null,
  },
  sort: null,
  loading: false,
}

export default function questionsReducer(state = initialState, action) {
  switch (action.type) {
    case actions.SET_STATE:
      return { ...state, ...action.payload }
    default:
      return state
  }
}
