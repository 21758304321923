import axios from 'axios'
import axiosRetry from 'axios-retry'
// import { notification } from 'antd'
import { firebaseAuth } from '../firebase'

const apiClient = axios.create({
  baseURL: 'https://api.stift.com.br/v1',
  // baseURL: 'http://192.168.1.20:3500/v1',
  // timeout: 1000,
  // headers: { 'X-Custom-Header': 'foobar' }
})

axiosRetry(apiClient, {
  retries: 3,
  retryDelay: axiosRetry.exponentialDelay,
})

apiClient.interceptors.request.use(async request => {
  request.headers.Authorization = await firebaseAuth.currentUser.getIdToken()
  return request
})

export default apiClient
