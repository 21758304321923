import { all, put, call, takeEvery, select } from 'redux-saga/effects'
import getQuestionsData from 'services/backend/questions'
import actions from './actions'

export function* GET_DATA({ payload }) {
  const { limit, offset, filters, query, sort } = payload

  yield put({
    type: 'questions/SET_STATE',
    payload: {
      loading: true,
    },
  })

  // get current filters from store
  const questionsState = yield select(state => state.questions)

  // use the current filters if not provided, and replace the ones that are provided
  const currentFilters = questionsState.filters
  const currentSort = questionsState.sort
  const currentQuery = questionsState.filters?.query
  const newFilters = filters === null ? {} : { ...currentFilters, ...filters }
  const newSort = sort === null ? null : sort || currentSort
  const newLimit = limit ?? questionsState.limit
  const newOffset = offset ?? questionsState.offset

  newFilters.query = query === null ? null : query || currentQuery

  const questions = yield call(getQuestionsData, {
    limit: newLimit,
    offset: newOffset,
    filters: newFilters,
    sort: newSort,
  })

  yield put({
    type: 'questions/SET_STATE',
    payload: {
      data: questions.data,
      count: questions.count,
      limit: newLimit,
      offset: newOffset,
      filters: newFilters,
      sort: newSort,
      loading: false,
    },
  })
}

export default function* rootSaga() {
  yield all([takeEvery(actions.GET_DATA, GET_DATA)])
}
