/* eslint-disable camelcase */
import backend from '../index'

export default async function getQuestions(
  { limit, offset, filters, sort } = { limit: 100, offset: 0, filters: null, sort: null },
) {
  const questions = await backend.get(`/questions`, {
    params: {
      limit,
      offset,
      query: filters?.query,
      start_date: filters?.start_date,
      end_date: filters?.end_date,
      school_ids: filters?.school_ids?.join(','),
      group_ids: filters?.group_ids?.join(','),
      discipline_ids: filters?.discipline_ids?.join(','),
      rating_range: filters?.rating_range?.join(','),
      status_id: filters?.status_id?.at(0),
      has_reply: filters?.has_reply,
      user_ids: filters?.user_ids?.join(','),
      sort: sort && sort.length === 2 ? `${sort[0]} ${sort[1]}` : null,
    },
  })
  return {
    data: questions.data,
    count: Number(questions.headers['x-total-count']),
  }
}
